// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-callcenter-index-js": () => import("./../../../src/templates/callcenter/index.js" /* webpackChunkName: "component---src-templates-callcenter-index-js" */),
  "component---src-templates-callcenter-login-js": () => import("./../../../src/templates/callcenter/login.js" /* webpackChunkName: "component---src-templates-callcenter-login-js" */),
  "component---src-templates-callcenter-password-js": () => import("./../../../src/templates/callcenter/password.js" /* webpackChunkName: "component---src-templates-callcenter-password-js" */),
  "component---src-templates-callcenter-signup-js": () => import("./../../../src/templates/callcenter/signup.js" /* webpackChunkName: "component---src-templates-callcenter-signup-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-invite-js": () => import("./../../../src/templates/invite.js" /* webpackChunkName: "component---src-templates-invite-js" */),
  "component---src-templates-invoice-js": () => import("./../../../src/templates/invoice.js" /* webpackChunkName: "component---src-templates-invoice-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-manage-index-js": () => import("./../../../src/templates/manage/index.js" /* webpackChunkName: "component---src-templates-manage-index-js" */),
  "component---src-templates-password-js": () => import("./../../../src/templates/password.js" /* webpackChunkName: "component---src-templates-password-js" */),
  "component---src-templates-reservation-index-js": () => import("./../../../src/templates/reservation/index.js" /* webpackChunkName: "component---src-templates-reservation-index-js" */)
}

